<template>
  <MainLayout :title="title">
    <div class="container activeos sealx glasspane_lighter pitch-mixin"
      data-augmented-ui="tl-clip tr-scoop b-clip-x both" style="margin-top: 100px;">
      <div class="flex justify-between items-center mb-6">
        <div class="text-sm">BlueTeam #UKWARUKRAINE</div>
        <div class="flex space-x-2">
          <button class="ui-btn minimize" @click="goBack">
            <svg x="0px" y="0px" viewBox="0 0 10.2 1" class="h-4 w-4">
              <rect x="0" y="50%" width="10.2" height="1" />
            </svg>
          </button>
          <button title="View Locked" class="ui-btn maximize">
            <svg viewBox="0 0 10 10" class="h-4 w-4">
              <path d="M0,0v10h10V0H0z M9,9H1V1h8V9z" />
            </svg>
          </button>
          <button class="ui-btn close" @click="goHome">
            <svg viewBox="0 0 10 10" class="h-4 w-4">
              <polygon
                points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1" />
            </svg>
          </button>
        </div>
      </div>
      <div class="text-center mb-6">
        <h2 class="text-xxl font-semibold">
          OpenSource | Development | Encryption | Design | Geospatial | Ai | Machine Learning | Blockchain | Web3 |
          OSINT
        </h2>
        <h4 class="text-lg">
          Just because it does not exist yet, does not mean we cannot create it.
        </h4>
      </div>
      <hr class="border-2 h-1 aa my-6" />
      <div class="text-center mb-6">
        <h2 class="text-xl font-semibold">Services</h2>

        <h4 class="text-lg">
          Unique designs limited only by your imagination. We adapt our services to meet the unique needs of each
          project.
        </h4>
      </div>
      <hr class="border-0 h-1 aa  my-6" />
      <div class="container flex flex-wrap justify-center">
        <div class="w-full lg:w-3/4">
          <ul class="list-disc list-inside mb-4">
            <li>App Development: From low-level assembly to high-level Swift</li>
            <li>Web Development: Comprehensive front to back-end with PHP, VueJS, React</li>
            <li>Web Application Hosting: Robust deployment solutions including LAMP, LEMP, and modern frameworks</li>
            <li>Containerization: Expertise in Docker, Kubernetes, and app packaging</li>
            <li>OS Modification: Custom solutions for Linux and Android systems</li>
            <li>Unified Infrastructure Support: Specialized in networks designed or overhauled by us</li>
            <li>Unified Communications: Custom professional VoIP systems</li>
          </ul>
          <p class="mb-4">
            Databases configured to match project requirements: MYSQL, MSSQL, MongoDB, NoSQL, Postgres.
            We prioritize the latest technologies for maximum efficiency and security.
          </p>
          <p class="text-white mb-4">
            Flexible pricing based on economic conditions. Special terms available for clients from lower-income
            economies. Contact us via Skype at AckerWorx for details on eligibility and pricing adjustments.

          </p>
        </div>
      </div>
      <div class="card5"></div>
      <audio id="osint" src="/frontend/mp3/findyou.ogg"></audio>
    </div>
  </MainLayout>
</template>

<script setup>
import MainLayout from '@/Layouts/MainLayout.vue';
import { usePage } from '@inertiajs/vue3';
const goBack = () => {
  window.history.back();
};

const goHome = () => {
  Inertia.visit('/');
};
const { props } = usePage();
const title = props.title;
</script>

<style scoped>
.aa {
  border-top: 3px solid rgba(97, 163, 255, 0.47) !important;
}
</style>
